// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$DRA-primary: mat.define-palette(mat.$indigo-palette, 900, 700, 500, 300); // Modified primary palette
$DRA-accent: mat.define-palette(mat.$deep-orange-palette, 900, 700, 500); // Modified accent palette
$DRA-neutral: mat.define-palette(mat.$grey-palette, 900, 700, 500); // Modified neutral palette

$DRA-theme: mat.define-light-theme((
  color: (
    primary: $DRA-primary,
    accent: $DRA-accent,
    warn: $DRA-neutral, 
  )
));

@include mat.all-component-themes($DRA-theme);

:root {
  --primary-900: #075D8B;
  --primary-700: #5C98B9;
  --primary-500: #87B7D1;
  --primary-300: #CCE3EF;
  --primary-100: #F0F9FE;

  --secondary-900: #DC3809;
  --secondary-700: #EC7857;
  --secondary-500: #FFAD96;
  --secondary-300: #FFDFD7;
  --secondary-100: #FFF7F4;

  --neutral-900: #283138;
  --neutral-800: #3A434B;
  --neutral-700: #5F6A75;
  --neutral-600: #7D8A97;
  --neutral-500: #9FA7B4;
  --neutral-400: #B5BFCE;
  --neutral-300: #CED6E2;
  --neutral-200: #E2E8F1;
  --neutral-100: #F1F4F9;

  --gradient: linear-gradient(to right, #126693 0%, #348FC0 100%);
}

.main-button{
  background-color:var(--primary-900);
  padding: 13px 32px 13px 32px;
  border-radius: 4px; 
  border: none;
  color:white;
  margin-bottom: 40px;
  font-weight: 600;
  cursor:pointer;
  font-size: 16px;
  margin-top: 10px;

}

.main-button:hover{
  background-color:#1d6f9b;

}

.secondary-button{
  background-color:white;
  padding: 13px 32px 13px 32px;
  border-radius: 4px; 
  border: 1px solid #075D8B;
  color:var(--primary-900);
  font-weight: 600;
  cursor:pointer;
}

.secondary-button:hover{
  border: 1px solid var(--primary-700);
  color:var(--primary-700)

}

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: rgb(240, 240, 240);
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
  }

h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 32px;
}

h2 {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 24px;
}

h3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 20px;
}

h4 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
}

h5 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.b1-r {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-size: 16px;
}

.b1-b {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.b2-r {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-size: 15px;
}

.b2-b {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 15px;
}

.b3-r {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-size: 12px;
}

.b3-b {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 12px;
}

.l1-b {
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-900);
  cursor: pointer

}

.l2-b{
  font-size: 14px;
font-weight: 600;
color: var(--neutral-900);

}

.l3-b{
  font-size: 12px;
font-weight: 400;
color: var(--neutral-800);

}


@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/static/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/static/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/static/OpenSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/Open_Sans/static/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;

}

.content {
  background-color: white;
  border-radius: 10px;
  margin-top: 20px;
  width: 40%;
  margin-bottom: 15px;

}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

}
 
.spinner {
  width: 50px;
  height: 50px;
  border: 6px solid #ccc;
  border-top-color: var(--secondary-900);
  border-radius: 50%;
  animation: spinner-rotation 1s linear infinite;
}

.spinnerText {
  color: var(--secondary-900);
}


@keyframes spinner-rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.inputContainer {
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
  text-align:center;
  
}


.icon-and-address {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50px;
  margin-bottom: 100px;
  

}

.back-arrow {
  cursor: pointer;
  background-color: var(--neutral-100);
  border-radius: 50px;
  height: 48px !important;
  width: 48px !important;
  position: absolute !important;
  left: 30px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 1;
  color: var(--neutral-800);
}

.back-arrow:hover {
  border: 1px solid var(--neutral-300);
}



.topAddress {
  display: flex;
  align-items: center;
  text-align: center;
  line-height: max-content;
  padding: 10px;
  background-color: var(--neutral-100);
  border-radius: 25px;
  z-index: 0;
  color: var(--neutral-900);
  flex-wrap: wrap; 
  margin-left: 10px;

  

}

.user-icon {
  cursor: pointer;
  background-color: var(--neutral-100);
  border-radius: 50px;
  height: 48px !important;
  width: 48px !important;
  position: absolute !important;
  right: 30px; 
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 1;
  color: var(--neutral-800);
}

.user-icon:hover {
  border: 1px solid var(--neutral-300);
}

.locationIcon {
  color: var(--primary-900);
}

.locationText {
  padding-right: 10px;
  padding-left: 10px;
  flex: 1; 
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}

.summaryContainer {
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  width: 100%;

}

.left-column {
  padding: 10px;
  width: 50% !important;
}

.right-column {
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 50% !important;

}


.epcDiv {
  display: flex;
  align-items: center;
}

.epcText {
  background-color: green;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  width: fit-content; 
  margin-left: 15px;
  margin-right: 15px; 
}

.epcGraphImage {
  width: 100px;
  height: auto;
  margin-left: 15px;
}

.epcNumber {
  font-size: 30px;
  font-weight: 700;
}

.epcLetter {
  font-size: 30px; 
  font-weight: 700;
  margin-left: 10px;
  color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.color-a { background-color: #007E2E; }
.color-b { background-color: #2C9F29; }
.color-c { background-color: #A1D20E; }
.color-d { background-color: #F8F400; }
.color-e { background-color: #F1C300; }
.color-f { background-color: #E77C17; }
.color-g { background-color: #DF0024; }


.buttonContainer {
  width: 70%;
  margin: 0 auto;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  
}


@media only screen and (max-width: 1300px) {


  .content{
    width: 95%;
  }

  .inputContainer{
    width: 50%
  }

  .epcDiv{
    width: 80%;
  }

  .summaryContainer {
    width: 80%;
  }

  .buttonContainer {
    width: 40%;  
  }


}

@media only screen and (max-width: 600px) {


  .inputContainer{
    width: 95%;
    margin-top: 5px;
  }

  .summaryContainer{
    width: 95%;
  }

  .epcDiv{
    width: 95%;
    margin-top: 35px;

  }

  .buttonContainer {
    width: 80%;  
  }

  .back-arrow {
    left: 10px;
  }

  .user-icon{
    right: 10px;
  }

  .topAddress {
    max-width: 65%;

  }

}